import { render, staticRenderFns } from "./Minilanding.vue?vue&type=template&id=aa4720b6&"
import script from "./Minilanding.vue?vue&type=script&lang=js&"
export * from "./Minilanding.vue?vue&type=script&lang=js&"
import style0 from "./Minilanding.vue?vue&type=style&index=0&id=aa4720b6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports