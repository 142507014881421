<template>
  <div class="portal-card mt-3" id="portal-card">
    <b-row style="margin-bottom: 30px;">
      <b-col md="2" lg="3"></b-col>
      <b-col md="8" lg="6">
        <div class="adaptive-container ">
          <div v-for="element in landing.elements">
            <template v-if="element.landing_type === 'chatbot'">
              <div v-if="element.ask_phone" class="lgt-call-to-action">
                <span>{{ element.phone_text }}</span>
              </div>
              <div v-if="element.links_text !== ''" class="lgt-call-to-action">
                <span>{{ element.links_text }}</span>
              </div>
              <div class="lgt-buttons-wrapper text-center">
                <div class="lgt-social-buttons">
                  <button v-if="landing.user_flow.chatbot_type === 'T'"
                          @click="openUrl(element.element_value)"
                          class="lgt-telegram-btn btn-telegram btn-telegram-custom"
                          style="border-radius: 5px !important; font-size: 20px; padding-top: 6px;">
                    <i class="fab fa-telegram-plane"></i>
                    Перейти в Telegram
                  </button>
                </div>
              </div>
            </template>
            <template v-else-if="element.landing_type === 'button'">
              <div class="lgt-buttons-wrapper text-center">
                <div class="lgt-social-buttons">
                  <button @click="openUrl(element.element_value)"
                          class="lgt-telegram-btn btn-telegram btn-telegram-custom"
                          style="border-radius: 5px !important; font-size: 20px; padding-top: 6px;">
                      {{ element.button_title }}
                  </button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="lgt-sections"
                   v-html="element.element_value">
              </div>
            </template>
          </div>
        </div>
      </b-col>
      <b-col md="2" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {api} from '@/api';

export default {
    name: 'Minilanding',
    props: [
        'landing',
    ],
    methods: {
      openUrl: function(url) {
        this.$emit('button-click');
        window.location.href = url;
      },
    },
    metaInfo() {
        return {
            title: this.$props.landing.meta_info.title,
            meta: [
                { name: 'description', content: this.$props.landing.meta_info.title },
                { property: 'og:title', content: this.$props.landing.meta_info.title },
                { property: 'og:description', content: this.$props.landing.meta_info.title },
                { property: 'og:image', content: this.$props.landing.meta_info.image },
            ],
        };
    },
};
</script>

<style>
body {
  background-color: white;
}
h1 {
  color: #5a5a5a;
}
h2 {
  color: #5a5a5a;
}
h3 {
  color: #5a5a5a;
}
p {
  color: #5a5a5a;
  font-size: 1rem;
}
strong {
  font-weight: 700;
}
b {
  font-weight: 700;
}
.lgt-call-to-action {
    animation: call-to-action .6s ease-in-out forwards;
    text-align: center;
    position: relative;
    margin: 6px 12px;
}
.lgt-call-to-action span {
    padding: 15px;
    font-size: 16px;
}
.lgt-call-to-action span {
    z-index: 2;
    color: #5a5a5a;
    position: relative;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    max-width: 70%;
}
.lgt-social-buttons button {
    margin-left: -1px;
    font-size: 11px;
    transition: .3s ease-in-out;
    justify-content: center;
    cursor: pointer;
    outline: none;
    padding: 4px 0 8px;
    color: white;
    width: 100%;
    border: none;
}
.btn-telegram-custom {
    width: 100% !important;
    max-width: 400px;
}
.btn-telegram {
    background: linear-gradient(to bottom, rgba(32,177,245,1) 0%, rgba(22,159,227,1) 100%);
}
.lgt-sections {
    margin-top: 5px;
    margin-bottom: 5px;
}
</style>