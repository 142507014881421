
import {Component, Vue} from 'vue-property-decorator';
import {api} from '@/api';
import Minilanding from './patterns/Minilanding.vue';
import {IUserLanding} from '@/interfaces';
import { setOptions, bootstrap } from 'vue-gtag';

@Component({
  components: {
    Minilanding,
  },
})
export default class LandingView extends Vue {
  public landingDetails: IUserLanding | null = null;
  public gtagPlugin: boolean = false;

  public async mounted() {
    const response = await api.getUserLandingDetails(this.$router.currentRoute.params.slug);
    this.landingDetails = response.data;
    this.handleAddLog('V');
    console.log('new');
    if (this.$router.currentRoute.params.slug === 'tm-zsbtt-0000001') {
      await this.enablePlugin();
    } else {
      console.log(this.$router.currentRoute.params.slug, this.$router.currentRoute.params.slug === 'tm-zsbtt-0000001');
    }
  }

  public async handleAddLog(type) {
    try {
      const data = {
        slug: this.$router.currentRoute.params.slug,
        log_type: type,
        client: navigator.userAgent,
      };
      await api.addLandingLog(data);
    } catch (e) {
      console.log(e);
    }
    if (this.gtagPlugin) {
      this.$gtag.event('button-click');
    }
  }

  public enablePlugin () {
    console.log('enabling');
    setOptions({
      config: { id: 'G-S7CG7RXLDX' },
    });
    bootstrap().then((gtag) => {
      console.log('bootstrap gtag ready', gtag);
      this.$gtag.pageview({ page_path: '/invite/tm-zsbtt-0000001' });
    });
    console.log('enabled');
    this.gtagPlugin = true;
    // this.$gtag.pageview({ page_path: '/invite/tm-zsbtt-0000001' });
  }

}
